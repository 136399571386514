@font-face {
    font-family: Muli;
    src: local(Muli-Light), url(../fonts/Muli-Light.woff2) format("woff2"), url(../fonts/Muli-Light.woff) format("woff"),
        url(../fonts/Muli-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Muli;
    src: local(Muli-Regular), url(../fonts/Muli-Regular.woff2) format("woff2"),
        url(../fonts/Muli-Regular.woff) format("woff"), url(../fonts/Muli-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Muli;
    src: local(Muli-Bold), url(../fonts/Muli-Bold.woff2) format("woff2"), url(../fonts/Muli-Bold.woff) format("woff"),
        url(../fonts/Muli-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Muli;
    src: local(Muli-ExtraBold), url(../fonts/Muli-ExtraBold.woff2) format("woff2"),
        url(../fonts/Muli-ExtraBold.woff) format("woff"), url(../fonts/Muli-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}

body {
    font-family: Muli;
    font-weight: normal;
    
    button,
    input,
    textarea,
    select {
        font-family: inherit;
    }

    a {
        text-decoration: none;

        &:hover, &:active, &:focus {
            text-decoration: none;
            box-shadow: none;
            outline: none;
            border: none;
        }
    }

    button:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}
